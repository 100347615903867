<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h3 class="layout-modal__title">{{ $t('user-portal.wallet_pin_configuration_title') }}</h3>
    </template>
    
    <forms-pins
      :errors="pinErrors"
      :steps="['pin', 'pin_confirmation']"
      @confirm="onPinConfirm"
      @input="onPinRemoveError"
      v-model="pins"
    />

  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'

import FormsPins from '@/components/forms/pins.vue'
import MixinErrors from '@/helpers/errors'

export default {
  name: 'ModalCreateWalletPins',

  components: {
    FormsPins,
    LayoutModal,
  },

  mixins: [
    MixinErrors,
  ],

  props: {
    errors: {},

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      pins: {
        pin: [],
        pin_confirmation: []
      },

      pinKey: 1,
      closing: false,
    }
  },

  computed: {
    attrs() {
      return {
        position: 'middle-center',
        title: this.$t('user-portal.wallet_pin_configuration_title'),
        visible: this.visible
      }
    },

    listeners() {
      return {
        close: this.back
      }
    },

    pinErrors() {
      let ret = this.pinKey && [
        ...this.getErrors('pin'),
        ...this.getErrors('pin_confirmation'),
      ]
      return ret
    },
  },

  methods: {
    back() {
      if(!this.closing) {
        this.closing = true
        this.$confirm({
          title: this.$t('user-portal.wallet_must_create_pin'),
          description: this.$t('user-portal.wallet_must_create_pin_message'),
          onPrimary: () => {
            this.$emit('close')
            this.closing = false
          },
          onSecondary: (e) => {
            this.closing = false
          }
        })
      }
    },
    
    onPinConfirm() {
      if(JSON.stringify(this.pins.pin) === JSON.stringify(this.pins.pin_confirmation)) {
        this.$emit('confirm', this.pins)
        return
      }

      this.errors['pin_confirmation'] = ['pin_confirmation_must_match_pin']
      this.pinKey = this.pinKey + 1
    },

    onPinRemoveError() {
      this.onRemoveError('pin')
      this.onRemoveError('pin_confirmation')
      this.pinKey = this.pinKey + 1
    },
  },

  // mounted() {
  //   this.pins = {
  //     pin: [],
  //     pin_confirmation: []
  //   }
  // }
}
</script>